<template>
    <v-app id="inspire">
      <v-navigation-drawer v-model="drawer">
        <v-list-item>
          <v-list-item-title class="title text-center pt-12 pb-9">
            <a href="/" style="font-size: 18px; text-decoration: none; color: #333;">{{ apptitle }}</a>
          </v-list-item-title>
        </v-list-item>
        <v-divider></v-divider>
  
        <v-list dense nav>
          <v-list-item v-for="item in items" :key="item.title" :to="item.to" link>
              <v-icon>{{ item.icon }}</v-icon>
              <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
  
      </v-navigation-drawer>
  
      <v-app-bar
          app
          dark 
          prominent 
          color="teal-darken-2" 
          height="120"
          image="top_pict.jpg"
      >
          <!-- Image slot -->
          <template v-slot:image>
              <v-img gradient="to top right, rgba(19,84,122,.8), rgba(128,208,199,.8)"></v-img>
          </template>

          <!-- Prepend slot -->
          <template v-slot:prepend>
              <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
          </template>

          <!-- Title slot -->
          <v-app-bar-title><a href="/" style="font-size: 24px; text-decoration: none; color: #EEE;">{{ apptitle }}</a></v-app-bar-title>

          <!-- Spacer -->
          <v-spacer></v-spacer>

          <!-- Avatar -->
          <v-tooltip text="Account" location="top">
            <template v-slot:activator="{ props }">
              <v-avatar v-bind="props" color="primary" size="36px">
                {{ userInitials }}  
              </v-avatar>
            </template>
          </v-tooltip>

          <!-- Tooltip with Logout button -->
          <v-tooltip text="Logout" location="top">
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" icon @click="handleLogout"><v-icon>mdi-logout</v-icon></v-btn>
            </template>
          </v-tooltip>
      </v-app-bar>
  
      <v-main>
        <router-view></router-view>
      </v-main>
    </v-app>
  </template>
  
  <script setup>
    import { ref, computed } from 'vue'
    const drawer = ref(null)
  </script>
  
  <script>
    
    import { mapActions } from 'vuex';

    const user = localStorage.getItem('username');
    const apptitle = process.env.VUE_APP_NAME;

    export default {
      computed: {
      userInitials() {
          const realname = localStorage.getItem('username') || '';
          const initials = realname
            .split(' ')
            .map(name => name.charAt(0))
            .join('');
            console.log(initials);
          return initials;
        }
      },
      methods: {
        ...mapActions('auth', ['logout']),

        async handleLogout() {
          console.log('handleLogout method called'); 
          try {
            await this.logout(); 
            // console.log('Logout successful'); 
            this.$router.push({ name: 'login' }); 
          } catch (error) {
            console.error('Error logging out:', error);
          }
        }
      },
      data: () => ({ 
        drawer: null,
        items: [
          { title: "Dashboard", icon: "mdi-monitor-dashboard", to: "/" },
          { title: "Vare label", icon: "mdi-printer", to: "/labels" },
          { title: "Ordre label", icon: "mdi-tray-full", to: "/orders" },
          { title: "Om", icon: "mdi-information-variant", to: "/about" },
        ]
      }),
    }
  </script>
