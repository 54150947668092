import axios from 'axios';

const state = {
  orders: [] // Initial state to store orders
};

const getters = {
  // Get all orders
  allOrders: (state) => state.orders,

  // Get a specific order by order number
  getOrderById: (state) => (orderNumber) => {
    return state.orders.find(order => order.number === orderNumber);
  },

  // Get order lines for a specific order
  getOrderLines: (state) => (orderNumber) => {
    const order = state.orders.find(order => order.number === orderNumber);
    return order ? order.lines : [];
  }
};

const actions = {
  async fetchOrders({ commit }) {
    // console.log('Fetching products with search term:', search);
    const userToken = localStorage.getItem('userToken'); 
    if (!userToken) {
        // Redirect to login page if token is not found
        router.push({ name: 'login' });
        return;
    }

    try {
      const response = await axios.get('https://api.datafangst.dk/api/rackbeatordre?limit=200', { headers: { Authorization: `Bearer ${userToken }` }, }); // Example endpoint
      commit('setOrders', response.data.orders); // Assuming response.data.orders matches your data structure
      // console.log(response.data.orders)
    } catch (error) {
      console.error('Error fetching orders:', error);
    }
  },

  async fetchOrderDetails({ commit }, orderNumber) {
    // console.log('Fetching products with search term:', search);
    const userToken = localStorage.getItem('userToken'); 
    if (!userToken) {
        // Redirect to login page if token is not found
        router.push({ name: 'login' });
        return;
    }

    try {
      const response = await axios.get(`https://api.datafangst.dk/api/rackbeatordre?search=${orderNumber}`, { headers: { Authorization: `Bearer ${userToken }` }, });
      console.log(response.data);
      commit('setOrders', response.data.orders);
    } catch (error) {
      console.error('Error fetching order details:', error);
    }
  }
};

const mutations = {
  setOrders: (state, orders) => {
    state.orders = orders;
  },
  addOrUpdateOrder: (state, order) => {
    const index = state.orders.findIndex(o => o.number === order.number);
    if (index !== -1) {
      // Update existing order
      state.orders[index] = order;
    } else {
      // Add new order
      state.orders.push(order);
    }
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
