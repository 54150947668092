import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    // component: HomeView,
    component: () => import(/* webpackChunkName: "dashboard" */ '../views/HomeView.vue'),
    beforeEnter: (to, from, next) => {
      const userToken = localStorage.getItem('userToken');
      if (userToken) {
        next();
      } else {
        next('/login'); // Redirect to login if user token doesn't exist
      }
    }
  },
  {
    path: '/about',
    name: 'About',
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/LoginView.vue')
  },
  {
    path: '/labels',
    name: 'Labels',
    component: () => import(/* webpackChunkName: "labels" */ '../views/LabelView.vue')
  },
  {
    path: '/orders',
    name: 'Orders',
    component: () => import(/* webpackChunkName: "orders" */ '../views/OrderView.vue')
  },
  {
    path: '/order/:id',
    name: 'Detail',
    component: () => import(/* webpackChunkName: "detail" */ '../views/DetailView.vue')
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
